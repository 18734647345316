$('.slider-for').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: false,
    dots: false,
    centerMode: false,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 2000,
    asNavFor: '.slider-nav'
});

$('.slider-nav').slick({
    slidesToShow: 6,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: false,
    arrows: false,
    centerMode: false,
    focusOnSelect: true
});


$('body :not(script)').contents().filter(function () {
    return this.nodeType === 3;
}).replaceWith(function () {
    return this.nodeValue.replace(/[™®]/g, '<sup>$&</sup>');
});
